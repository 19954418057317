import {RotationConfig} from '../RotationConfig'
import RotationReplacer from '../RotationReplacer'
import {doWhenP} from '../utils'
import {getRequestId, getPromoCode, fetchTokenNumbers} from '../Mapi'

export function getRotationConfig() {
  return global.MAPI && global.MAPI.config
}

export function getRotation() {
  return global.MAPI && global.MAPI.Rotation
}

export function replacePhoneNumbers() {
  return doWhenP(() => global.MAPI && global.MAPI.refreshNumbers).then(() => global.MAPI.refreshNumbers())
}

export function run() {
  return doWhenP(() => getPromoCode() && getRequestId() && getRotationConfig()).then(() => {
    global.MAPI.Rotation = global.MAPI.Rotation || new RotationConfig({})
    const repl = new RotationReplacer()
    const rot = global.MAPI.Rotation

    rot.setConfig(getRotationConfig())
    repl.setConfig(rot)

    global.MAPI.refreshNumbers = repl.replaceRotated.bind(repl)
    global.MAPI.watchForNumbers = repl.watchForReplacement.bind(repl)
    global.MAPI.replacePhoneNumbers = global.MAPI.refreshNumbers // renaming to not break backward compat
    global.MAPI.fetchTokenNumbers = fetchTokenNumbers

    return repl
  })
}
