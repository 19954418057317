/* eslint-disable */
/* eslint-env browser */
import { toArray, any, map, filter, each, zipMap } from './utils'
import { stripNumber, getTrimmableWhitespace } from './PhoneFormatters'
import * as Env from './Env'
import EventEmitter from './EventEmitter'
import findAndReplaceDOMText from './findAndReplaceDOMText'

const phoneFinderRegex = () => /1?[- ()]?\d{3}[- ()]?\d{3}[- ()]?\d{4}/g

/**
 * This will search the DOM for which token numbers this particular replacer needs to do its work,
 * along with doing the actual replacing.
 *
 * We need the search step so we can batch requests to MAPI to only those tokens we actually need.
 */
/**
 * @param {string|string[]} selectors
 * @param {string} defaultToken
 * @param {function|null} formatter (phone: string) => string
 * @param {function|function[]|null} filters (tokenNumbers) => bool
 * @param {string|string[]|null} numbersToReplace Whitelist of phone numbers to replace
 * @param {function|null} domQuery (selector: string, parentNode?: DOMElement) => DOMElement[]
 */
function DomReplacer(
  selectors,
  defaultToken,
  formatter = null,
  filters = null,
  numbersToReplace = [],
  domQuery = null
) {
  this.selectors = toArray(selectors || [])
  this.numbersToReplace = filter(
    map(toArray(numbersToReplace || []), stripNumber),
    (p) => p && p !== ''
  )

  this.formatter = formatter || ((n) => n)
  this.filters = toArray(filters || [])
  this.domQuery =
    domQuery || ((sel, el) => map((el || document).querySelectorAll(sel)))
  this.defaultToken = defaultToken

  this._replacementTokens = {}
  this._finders = []
  this._allowedTokens = []

  this.events = new EventEmitter()
}

DomReplacer.prototype = {
  constructor: DomReplacer,

  setAllowedTokens(tokens) {
    Env.info('allowing tokens', tokens)
    this._allowedTokens = tokens
  },

  _findDomElementsToReplace(selectors) {
    const foundParents = []
    Env.info('checking for selectors', selectors)

    each(selectors, (sel) => {
      each(this.domQuery.call(null, sel), (el) => foundParents.push(el))
    })

    this.events.emit('replacement-parents', foundParents, this)

    return foundParents
  },

  /**
   * Used to actually replace the text in the DOM
   *
   * @param {{}} tokenNumbers
   * @param {string} defaultToken
   * @param result
   * @returns {*}
   * @private
   */
  _replaceCb(tokenNumbers, defaultToken, result) {
    const strippedFound = stripNumber(result.text)
    if (!strippedFound) return result.text

    if (!strippedFound.match(phoneFinderRegex())) return ''

    const ws = getTrimmableWhitespace(result.text)
    const before = ws[0]
    const after = ws[1]

    const { node } = result
    const token = this._getTokenForNode(node, defaultToken)
    const outputNumber = tokenNumbers[token]

    if (!outputNumber) {
      Env.info('No token number found', token, tokenNumbers)
      return result.text
    }

    const formattedOutput = `${before}${outputNumber}${after}`

    if (this.allowReplacement(strippedFound, token, node)) {
      this.events.emit('replace-phone-number', outputNumber, node)
      return formattedOutput
    }

    this.events.emit('not-replacing-phone-number', outputNumber, node)

    return result.text
  },

  _allowNumber(number) {
    return (
      this.numbersToReplace.length === 0 ||
      this.numbersToReplace.indexOf(number) !== -1
    )
  },

  _allowToken(token) {
    return (
      this._allowedTokens.length === 0 ||
      this._allowedTokens.indexOf(token) !== -1
    )
  },

  _isFiltered(number, token, node) {
    if (this.filters.length === 0) return false
    return any(this.filters, (f) => !f.call(null, number, token, node))
  },

  allowReplacement(number, token, node) {
    const [byNum, byToken, byFilter] = [
      this._allowNumber(number),
      this._allowToken(token),
      !this._isFiltered(number, token, node),
    ]
    Env.info('allowed', number, token, { byNum, byToken, byFilter })
    return byNum && byToken && byFilter
  },

  /**
   * Used to investigate the DOM for this replacer to determine if there're any replacements
   *
   * @param result
   * @returns {*}
   * @private
   */
  _findCb(result) {
    const strippedFound = stripNumber(result.text)
    if (!strippedFound) return result.text

    const { node } = result
    const token = this._getTokenForNode(node)

    if (this.allowReplacement(strippedFound, token, node)) {
      this._replacementTokens[token] = true
    }

    return result.text
  },

  /**
   * Traverse up the DOM tree, looking for the first data-token element we find.
   * Since the nodes that come into this function will be leaf text nodes, we'll
   * always find the appropriate wrapping element (or none, of course)
   *
   * @param node
   * @param defaultToken
   * @returns {string}
   * @private
   */
  _getTokenForNode(node, defaultToken = null) {
    if (!node) {
      Env.critical('No node passed to _getTokenForNode')
      return defaultToken
    }

    let current = node

    while (true) {
      if (this._getElToken(current)) {
        break
      }

      if (!current.parentElement) break

      current = current.parentElement
    }

    const nodeToken = this._getElToken(current)
    const token = nodeToken || defaultToken || this.defaultToken

    this.events.emit('found-token', token, node, current)

    return token
  },

  _getElToken(el) {
    return el && el.getAttribute && el.getAttribute('data-token')
  },

  _reloadFinders() {
    this._finders = map(
      this._findDomElementsToReplace(this.selectors),
      (el) => {
        return this._getPhoneFinder(el)
      }
    )
  },

  /**
   * Search the DOM, looking for potential phone numbers we should replace.
   * Used to batch up tokens before we request MAPI.
   *
   * @returns {Array}
   */
  getTokensToReplace() {
    // We've already created the finders, just get out
    if (this._finders.length > 0) {
      return Object.keys(this._replacementTokens)
    }

    this._replacementTokens = {}
    this._reloadFinders()

    return Object.keys(this._replacementTokens)
  },

  _replaceForHref(el, defaultToken, tokenNumbers) {
    if (!el.attributes.href) return
    const { value } = el.attributes.href

    if (!value.match(/^tel:/)) return

    const stripped = stripNumber(value.replace(/^tel:/, ''))
    const noWhitelist = this.numbersToReplace.length === 0
    const whitelistHasNumber = this.numbersToReplace.indexOf(stripped) !== -1
    const token = this._getTokenForNode(el, defaultToken)
    const outputNumber = stripNumber(tokenNumbers[token])

    if (outputNumber && (noWhitelist || whitelistHasNumber)) {
      el.setAttribute('href', `tel:${outputNumber}`)
      this.events.emit('replace-phone-number', outputNumber, el)
    }
  },

  _getPhoneFinder(el, cb = null) {
    cb = cb || this._findCb.bind(this)

    return findAndReplaceDOMText(el, {
      find: phoneFinderRegex(),
      replace: cb,
    })
  },

  performReplacement(tokenNumbers) {
    this._reloadFinders()

    if (!this.getTokensToReplace().length) return false
    tokenNumbers = zipMap(tokenNumbers, (value, key) => [
      key,
      this.formatter.call(null, value),
    ])

    each(this._finders, (finder) => {
      const { node } = finder
      const nodeToken = this._getTokenForNode(node)

      this._getPhoneFinder(
        node,
        this._replaceCb.bind(this, tokenNumbers, nodeToken)
      )
      this._replaceForHref(node, nodeToken, tokenNumbers)

      const childAs = this.domQuery.call(null, 'a[href^="tel"]', node)
      each(childAs, (a) => {
        this._replaceForHref(a, nodeToken, tokenNumbers)
      })
    })

    return true
  },
}

export default DomReplacer
