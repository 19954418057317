import domReady from 'domready'
import {run as baseRun} from './RotationBase'

export function run() {
  return new Promise(res => {
    domReady(() => {
      baseRun().then(repl =>
        repl.replaceRotated().then(r => {
          repl.watchForReplacementIfConfigured()
          res(r)
        }),
      )
    })
  })
}
