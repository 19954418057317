import {runMapiModules} from './modules/allModules'
import * as Rotation from './lib/additional/RotationBase'
import {doWhenP} from './lib/utils'
import getVersion from './lib/getPackageVersion'
import RotationReplacer from './lib/RotationReplacer'
import {
  getPromoCodePromise,
  fetchTokenNumbers,
  replacePhoneNumbers,
  updateRequest,
  updateRequestWhenAvailable,
  updateRequestPriority,
  datalayerPush,
  datalayerEvent,
  getRequestIdPromise,
  getRequestData,
  getFromDataLayer,
  setup,
  setupSigil,
} from './lib/Mapi'

export interface BootstrapConfig {
  environment?: 'development' | 'staging' | 'production'

  /**
   * Brand abbreviation for the current site
   */
  brand: string

  /**
   * Lets you define a default rotation token other than the brand
   */
  defaultToken?: string

  /**
   * Force a domain to send to MAPI, *and* the domain for any cookies set.
   * Defaults to window.location.hostname
   */
  forceDomain?: string

  /**
   * Force a path to send to MAPI. Defaults to window.location.pathname
   */
  forcePath?: string

  /**
   * Lets you define a default promo code in the case of no kbid / promo_path_mapping mapi-side
   */
  defaultPromoCode?: string

  /**
   * Lets you define a default phone number
   */
  defaultPhoneNumber?: string
}

function bootstrap(config: BootstrapConfig): Promise<RotationReplacer> {
  runMapiModules()
  return setup(config).then(Rotation.run)
}

function bootstrapSigil(config: BootstrapConfig) {
  runMapiModules()
  setupSigil(config)
  return Rotation.run()
}

export {
  fetchTokenNumbers,
  updateRequest,
  updateRequestWhenAvailable,
  updateRequestPriority,
  replacePhoneNumbers,
  datalayerPush,
  getFromDataLayer,
  datalayerEvent,
  getRequestData,
  bootstrap,
  bootstrapSigil,
  doWhenP,
  getVersion,
  getPromoCodePromise as getPromoCode,
  getRequestIdPromise as getRequestId,
}
