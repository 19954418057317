function usRegex() {
  return /(\d{3})(\d{3})(\d{4})/
}

function stripNumber(phone) {
  return `${phone}`
    .replace(/^\s*|\s*$/g, '')
    .replace(/[^\d]/g, '')
    .replace(/^1(\d{10})/, '$1')
}

function UsDashes(phone, addOne) {
  return stripNumber(phone).replace(usRegex(), `${addOne ? '1-' : ''}$1-$2-$3`)
}

function UsDots(phone, addOne) {
  return stripNumber(phone).replace(usRegex(), `${addOne ? '1.' : ''}$1.$2.$3`)
}

function UsParen(phone) {
  return stripNumber(phone).replace(usRegex(), '($1) $2-$3')
}

function getFromName(name) {
  switch ((name || '').toLowerCase()) {
    case 'us':
      return UsDashes
    case 'usaddone':
      return p => UsDashes(p, true)
    case 'usdots':
      return UsDots
    case 'usdotsaddone':
      return p => UsDots(p, true)
    case 'usparen':
      return UsParen
    default:
      return UsDashes
  }
}

function getTrimmableWhitespace(str) {
  // eslint-disable-next-line no-param-reassign
  str = str || ''
  const before = str.match(/(^\s+)/g)
  const after = str.match(/(\s+$)/g)

  return [before || '', after || '']
}

module.exports = {
  stripNumber,
  UsDashes,
  UsDots,
  UsParen,
  getFromName,
  getTrimmableWhitespace,
}
